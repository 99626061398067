import { Grid } from "@mui/material";
import "./Styles.css";
import { Link } from "react-router-dom";
import IconsDataService from "../Services/icons";
import React, { useState, useEffect, useMemo } from "react";

function Fruit() {
  const [isLoading, setLoading] = useState(false);
  const type = "voće";
  const foodTypes = useMemo(
    () => ["svježe", "sušeno", "orašasto", "sjemenke", "bobice", "masno", "super"],
    []
  );

  const [iconsArray, setIconsArray] = useState([]);

  useEffect(() => {
    async function fetchIconsData() {
      try {
        setLoading(true);
        setIconsArray(await getIconsArray());
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    fetchIconsData();
  }, []);

  return (
    <div className={isLoading ? "op0" : ""}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[0]}`}>
            <img className="images" src={iconsArray[foodTypes[0]]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[1]}`}>
            <img className="images" src={iconsArray[foodTypes[1]]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[2]}`}>
            <img className="images" src={iconsArray[foodTypes[2]]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[3]}`}>
            <img className="images" src={iconsArray[foodTypes[3]]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[4]}`}>
            <img className="images" src={iconsArray[foodTypes[4]]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${type}&subtype=${foodTypes[5]}`}>
            <img className="images" src={iconsArray[foodTypes[5]]} alt="" />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

async function getIconsArray() {
  const arr = await IconsDataService.getAll();
  return arr.data;
}

export default Fruit;
