import http from "../http-common";
import axios from 'axios';

const API_URL = 'http://localhost:5000/api';
// Promjeniti na http-common.js baseURL

class FoodDataService {

    getFiltered(str) {
        return http.get(`/food${str}`);
    }

    getDistinct(dist) {
        return http.get(`/distinctFood${dist}`);
    }

    getSubtypes() {
        return http.get(`/foodSubtypes`);
    }

    async getFoodByType(type, subtype) {
        try {
            const response = await axios.get(`${API_URL}/foods/type/${type}/subtype/${subtype}`);
            return response.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    }
}

export default new FoodDataService();