import "./Styles.css";
import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import IngredientsDataService from "../Services/ingredients";
import FoodDataService from "../Services/food";

function Smoothie() {
  const [driedEl, setDriedEl] = useState([]);
  const [nutsEl, setNutsEl] = useState([]);
  const [seedsEl, setSeedsEl] = useState([]);
  const [berriesEl, setBerriesEl] = useState([]);
  const [superEl, setSuperEl] = useState([]);
  const smoothieTypes = useMemo(
    () => ["svježe", "sušeno", "orašasto", "sjemenke", "bobice", "super"],
    []
  );

  useEffect(() => {
    async function fetchIngredientsData() {
      try {
        setDriedEl(await getIngredients(smoothieTypes[1]));
        setNutsEl(await getIngredients(smoothieTypes[2]));
        setSeedsEl(await getIngredients(smoothieTypes[3]));
        setBerriesEl(await getIngredients(smoothieTypes[4]));
        setSuperEl(await getIngredients(smoothieTypes[5]));
        getArray();
      } catch (err) {
        console.log(err);
      }
    }
    fetchIngredientsData();
  }, [smoothieTypes]);

  return (
    <div className="smoothie-container">
      <Grid container spacing={1}>
        <Grid item xs={4} key={1}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{smoothieTypes[0]}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[0])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="0">
            <p
              id={`ing-${smoothieTypes[0]}-0`}
              className="ingredient-items"
              onClick={() => itemClick(`ing-${smoothieTypes[0]}-0`)}
            >
              slatko
            </p>
            <p
              id={`ing-${smoothieTypes[0]}-1`}
              className="ingredient-items"
              onClick={() => itemClick(`ing-${smoothieTypes[0]}-1`)}
            >
              kiselo
            </p>
          </div>
        </Grid>
        <Grid item xs={4} key={2}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{smoothieTypes[1]}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[1])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="1">
            {driedEl}
          </div>
        </Grid>
        <Grid item xs={4} key={3}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{smoothieTypes[2]}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[2])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="2">
            {nutsEl}
          </div>
        </Grid>
        <Grid item xs={4} key={4}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{smoothieTypes[3]}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[3])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="3">
            {seedsEl}
          </div>
        </Grid>
        <Grid item xs={4} key={5}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{smoothieTypes[4]}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[4])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="4">
            {berriesEl}
          </div>
        </Grid>
        <Grid item xs={4} key={6}>
          <div className="ingredient-label-container">
            <p className="ingredient-label">{"protein+\nkreatin"}</p>
            <button
              className="ingredient-plus-1"
              onClick={() => plusOne(smoothieTypes[5])}
            >
              +1
            </button>
          </div>
          <div className="ingredients-list" id="5">
            {superEl}
          </div>
        </Grid>
        <Grid item xs={4} key={7}></Grid>
        <div className="smoothie-dock">
          <button
            className="plus-one-all"
            onClick={() => plusOneAllClick(smoothieTypes)}
          >
            +1
          </button>
        </div>
      </Grid>
    </div>
  );
}

async function getIngredients(subtype) {
  let elements = [];
  let query = `?type=voće&subtype=${subtype}&stock=1`;
  if (subtype === "super") {
    query = `?subtype=super&stock=1`;
  }
  const ing = await FoodDataService.getFiltered(query);
  let array = [];
  for (const [x, y] of ing.data.entries()) {
    const key = `${x}`;
    const id = `ing-${subtype}-${x}`;
    const item = (
      <p
        key={key}
        id={id}
        className="ingredient-items"
        onClick={() => itemClick(id)}
      >
        {y.name}
      </p>
    );
    array.push(item);
  }
  elements.push(array);
  return elements;
}

function itemClick(id) {
  const element = document.getElementById(id);
  const parentId = element.parentNode.id;
  var siblings = document.getElementById(parentId).childNodes;
  for (let i = 0; i < siblings.length; i++) {
    siblings[i].classList.add("unselected-items");
    siblings[i].classList.remove("selected-item");
  }
  element.classList.remove("unselected-items");
  element.classList.add("selected-item");
  setItem(id);
}

async function getArray() {
  const res = await IngredientsDataService.getArray();
  const array = res.data;
  const arrayFromObject = Object.entries(array).map(([key, value]) => ({
    key,
    value,
  }));
  arrayFromObject.forEach((e) => {
    const id = `ing-${e.key}-${e.value}`;
    const ex = document.getElementById(id);
    if (ex) {
      ex.classList.remove("unselected-items");
      ex.classList.add("selected-item");
    }
  });
}

async function setItem(id) {
  const x = id.substring(id.indexOf("-") + 1, id.lastIndexOf("-"));
  const y = parseInt(id.substring(id.lastIndexOf("-") + 1));
  const arr = await IngredientsDataService.getArray();
  const ingredients = arr.data;
  ingredients[x] = y;
  await IngredientsDataService.setArray(ingredients);
}

async function plusOne(type) {
  var allElements = document.getElementsByTagName("*");
  var count = 0;
  var selected = 0;
  var newSelected = 0;
  for (var i = 0; i < allElements.length; i++) {
    var element = allElements[i];
    if (element.id && element.id.indexOf(type) !== -1) {
      element.classList.remove("unselected-items");
      if (element.classList.contains("selected-item")) {
        selected = parseInt(
          element.id.substring(element.id.lastIndexOf("-") + 1)
        );
      }
      element.classList.remove("selected-item");
      count++;
    }
  }

  if (selected < count - 1) {
    newSelected = selected + 1;
  } else {
    newSelected = 0;
  }

  const newId = `ing-${type}-${newSelected}`;
  const newElement = document.getElementById(newId);
  newElement.classList.add("selected-item");
  newElement.classList.remove("unselected-items");

  const arr = await IngredientsDataService.getArray();
  const ingredients = arr.data;
  ingredients[type] = newSelected;
  await IngredientsDataService.setArray(ingredients);
}

async function plusOneAllClick(smoothieTypes) {
  var allElements = document.getElementsByTagName("*");
  var count = 0;
  var selected = 0;
  var smoothieTypesArray = [];

  for (var i = 0; i < allElements.length; i++) {
    var element = allElements[i];
    count = 1;
    for (var j = 0; j < smoothieTypes.length; j++) {
      if (element.id && element.id.indexOf(`ing-${smoothieTypes[j]}`) !== -1) {
        if (element.classList.contains("selected-item")) {
          selected = parseInt(
            element.id.substring(element.id.lastIndexOf("-") + 1)
          );
        }
        if (smoothieTypesArray[j]) {
          count = smoothieTypesArray[j][2];
          count++;
        }
        smoothieTypesArray[j] = [smoothieTypes[j], selected, count];
      }
    }
  }

  for (var k = 0; k < allElements.length; k++) {
    var el = allElements[k];
    for (var l = 0; l < smoothieTypes.length; l++) {
      if (el.id && el.id.indexOf(`ing-${smoothieTypes[l]}`) !== -1) {
        el.classList.remove("selected-item");
        el.classList.remove("unselected-items");
      }
    }
  }

  for (var m = 0; m < allElements.length; m++) {
    var ele = allElements[m];
    for (var n = 0; n < smoothieTypes.length; n++) {
      if (ele.id === `ing-${smoothieTypes[n]}-${smoothieTypesArray[n][1]}`) {
        if (smoothieTypesArray[n][1] < smoothieTypesArray[n][2] - 1) {
          var sel = document.getElementById(
            `ing-${smoothieTypes[n]}-${smoothieTypesArray[n][1] + 1}`
          );
          sel.classList.add("selected-item");
          sel.classList.remove("unselected-items");
        } else {
          var newSelected = document.getElementById(
            `ing-${smoothieTypes[n]}-0`
          );
          newSelected.classList.add("selected-item");
          newSelected.classList.remove("unselected-items");
        }
      }
    }
  }

  const ing = await IngredientsDataService.getArray();
  const ingredients = ing.data;
  for (var o = 0; o < smoothieTypes.length; o++) {
    if (ingredients[smoothieTypes[o]] < smoothieTypesArray[o][2] - 1) {
      ingredients[smoothieTypes[o]] = smoothieTypesArray[o][1] + 1;
    } else {
      ingredients[smoothieTypes[o]] = 0;
    }
  }
  await IngredientsDataService.setArray(ingredients);
}

export default Smoothie;
