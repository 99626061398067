import { Grid } from "@mui/material";
import "./Styles.css";
import { Link } from "react-router-dom";
import IconsDataService from "../Services/icons";
import React, { useState, useEffect } from "react";

function Vegetable() {
  const [isLoading, setLoading] = useState(false);
  // const type = "povrće";
  // const foodTypes = useMemo(
    // () => ["povrće2", "sušeno", "orašasto", "sjemenke", "bobice", "masno", "super"],
    // []
  // );

  const [iconsArray, setIconsArray] = useState([]);

  useEffect(() => {
    async function fetchIconsData() {
      try {
        setLoading(true);
        setIconsArray(await getIconsArray());
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    fetchIconsData();
  }, []);

  return (
    <div className={isLoading ? "op0" : ""}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"povrće"}&subtype=${"voće"}`}>
            <img className="images" src={iconsArray["povrće2"]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"povrće"}&subtype=${"korijen"}`}>
            <img className="images" src={iconsArray["korijen"]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"trava"}&subtype=${"žitarica"}`}>
            <img className="images" src={iconsArray["žitarica"]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"mahunarka"}&subtype=${"mahunarka"}`}>
            <img className="images" src={iconsArray["mahunarka"]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"zeleno"}&subtype=${"zeleno"}`}>
            <img className="images" src={iconsArray["zeleno"]} alt="" />
          </Link>
        </Grid>
        <Grid item xs={6} xl={3}>
          <Link to={`/foods?type=${"ostalo"}&subtype=${"ostalo"}`}>
            <img className="images" src={iconsArray["ostalo"]} alt="" />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

async function getIconsArray() {
  const arr = await IconsDataService.getAll();
  return arr.data;
}

export default Vegetable;
